import React, { useCallback, useEffect, useMemo, useState } from 'react';
import logo from './logo.png';
import './App.css';
import styled from 'styled-components';
import gbImage from './bg.jpg'
import tree from './tree.png'
import hexagon from './hexagon.svg'
import start from './start.svg'
import hexagonActive from './hexagonActive.svg'
import bar from './bar.png';
import sale from './sale.png';
import sert from './sert.png';
import taxi from './taxi.png'
import bottle from './bottle.svg'
import instIcon from './inst.svg'
import { HashRouter, Link, Route, Switch, useLocation } from 'react-router-dom';
import { setTokenSourceMapRange } from 'typescript';


// alert(window.innerWidth)
// setTimeout(() => {
//   alert(window.innerHeight)
//   alert(window.outerHeight)
// }, 5000)
interface Game { quantity?: number, text: string, note?: string, image: any}

type DataType = {
  bar?: number,
  bottle?: number,
  id?: number,
  sale_t?: number,
  sale_today?: number,
  sert?: number,
  tablet_number?: number,
  taxi?: number,
}

const defautValues: DataType = {
  bar: 4,
  bottle: 3,
  id: 5,
  sale_t: 5,
  sale_today: 5,
  sert: 3,
  tablet_number: 5,
  taxi: 5,
}

export const Layout:React.FC = ({children}) => {
  return  <AppComp>
    <ContainerComp>
      <HashRouter>
        <App></App>
      </HashRouter>
      </ContainerComp>
    </AppComp>
}

interface Games {
  taxi: Game,
  sert: Game,
  sale_today: Game,
  bar: Game,
  bottle: Game,
  sale_t: Game,
}

const indexes: ('sale_today' | 'bar' | 'bottle' | 'sale_t' | 'taxi' | 'sert')[]  = ['bar', 'sale_t', 'bottle', 'sale_today', 'taxi', 'sert'];

const useGames = () => {
  
  const [ games, setGames ] = useState<Games>({
    taxi: {
      text: 'Промокод ',
      note: 'на поездку <br>в такси',
      image: taxi,
    },
    sert: {
      text: 'Сертификат',
      image: sert,
      note: 'на 2 000 ₽'
    },
    sale_today: {
      text: 'Скидка',
      image: sale,
      note: 'на текущий <br>коктейль',
    },
    bar: {
      text: 'Коктейль',
      image: bar,
    },
    bottle: {
      text: 'Термобутылка',
      image: bottle,
    },
    sale_t: {
      text: 'Скидка',
      image: sale,
      note: 'на следующий <br>коктейль'
    },
  });

  const win = (id: number) => setGames(g => {
    const gameName = indexes[id];
    return {...g, [gameName]: {...g[gameName], quantity: (g[gameName].quantity || 0) - 1 < 0 ? 0 : (g[gameName].quantity || 0) - 1}};
  })

  const addItem = (name: keyof Games) => setGames(g => ({...g, [name]: {...g[name], quantity: (g[name].quantity || 0) + 1}}));



  const setGamesQantity = (data: DataType) => {
    console.log({data});
    const gam = {...games};
    Object.keys(data).forEach((key) => {
      const game = gam[key as keyof Games];
      if (game) {
        game.quantity = data[key as keyof DataType] || 0;
      }
    })
    console.log({gam});
    
    setGames(g => {
      return {...g, ...gam};
    })
  }

  const removeItem = (name: keyof Games) => setGames(g => {
    return {...g, [name]: {...g[name], quantity: (g[name].quantity || 0) - 1 < 0 ? 0 : (g[name].quantity || 0) - 1}};
  })

  useEffect(() => {
    const gamesQ: DataType = Object.keys(games).reduce((res, key) => games[key as keyof Games].quantity === undefined ? res : ({...res, [key]: games[key as keyof Games].quantity}), {});
    console.log({gamesQ: +(localStorage.getItem('tablet_number') || 0)}, JSON.stringify({...gamesQ, id: +(localStorage.getItem('tablet_number') || 0)}));
    if (Object.keys(gamesQ).length) {
      const updateRequest = new FormData();
      Object.keys(gamesQ).forEach(key => {
        updateRequest.append(key, (gamesQ[key as keyof DataType] || 0).toString());
      })
      updateRequest.append('id', (localStorage.getItem('tablet_number') || ''));
      fetch('dbconnector.php', {
        method: 'POST',
        body: JSON.stringify({...gamesQ, id: localStorage.getItem('tablet_number')}),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(async res => {
        console.log({res});
      });
    }
    
  }, [games])
  
  return {games, win, addItem, removeItem, setGamesQantity}
}
function App() {

  const { games, win, addItem, removeItem, setGamesQantity } = useGames();

  const location = useLocation();
  const [hexPlay, setHexPlay] = useState(0);
  const [isAuth , setIsAuth] = useState(false);
  const [name , setName] = useState('');
  const [password , setPassword] = useState('');
  const [active, setActive] = useState<string | null>(null);
  const [deg, setDeg] = useState(0);
  const [hexdeg, setHexdeg] = useState(0);

  useEffect(() => {
    let tabletNumber = localStorage.getItem('tablet_number');
    if (!tabletNumber) {
      fetch('dbconnector.php', { method: 'post', headers: {
        'Content-Type': 'application/json'
      }}).then(res=> res.json()).then(async res => {
        setGamesQantity(res);
        localStorage.setItem('tablet_number', res.id);
      });
    } else {
      fetch(`dbconnector.php?id=${tabletNumber}`).then(res => res.json()).then(res => {
        setGamesQantity(res);
      });
    }
    
  }, [])

  useEffect(() => {
    if (name === '123' && password === '123') {
      setIsAuth(true)
    } else {
      setIsAuth(false);
    }
  }, [name, password])

  useEffect(() => {
    if (location.pathname !== '/prizes') {
      setName('')
      setPassword('')
    }
  }, [location])

  const reset = () => {
    setGamesQantity({
      bar: defautValues.bar,
      bottle: defautValues.bottle,
      sale_t: defautValues.sale_t,
      sale_today: defautValues.sale_today,
      sert: defautValues.sert,
      taxi: defautValues.taxi,
    });
  }

  const play = useCallback(() => {
    console.log('!!!!!!!!!!!');
    
    let names:('sale_today' | 'bar' | 'bottle' | 'sale_t' | 'taxi' | 'sert')[] = (Object.keys(games) as Array<keyof typeof games>).filter(el => (games[el as keyof Games].quantity || 0) > 0);
    console.log({names});
    
    if (!names.length) {
      reset();
      names = (Object.keys(games) as Array<keyof typeof games>).filter(el => (games[el as keyof Games].quantity || 0) > 0);
    }
    let id = Math.floor(Math.random() * Math.floor(names.length));
    
    const name: ('sale_today' | 'bar' | 'bottle' | 'sale_t' | 'taxi' | 'sert') = names[id];
    
    if (!name) {
      return
    }
    const idR = indexes.indexOf(name);
    
    win(idR);
    const SPIN = 30;

    setDeg(deg => deg - (deg%360 + (idR + SPIN) * 60));
    setHexdeg(deg => deg + (360 - deg%360 + idR * 60));
    setHexPlay(deg => deg - deg%360 + (idR + SPIN) * 60);
    setActive(null);
    setTimeout(() => {
      setActive(name);
    }, 5000)

  }, [games, win]);

  const setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value)
  }

 

  const login = () => {
    fetch('dbconnector.php', {
        method: 'POST',
        body: JSON.stringify({password, id: localStorage.getItem('tablet_number')}),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res=> res.json()).then(ress => {
        console.log({ress});
        
        if (ress) {
          setIsAuth(true);
        }
      });
  }
  const tablet_number = localStorage.getItem('tablet_number');
  return (<>
        <Logo to="/prizes" >
          <img src={logo} alt="logo" />
        </Logo>
        <LinkInst href="https://instagram.com/rokuginlady?igshid=zdjjr22vzqv2" target="_blank" rel="noreferrer"><img src={instIcon} alt="instLogo" /> @rokuginlady</LinkInst>
        <Switch>
          <Route path="/" exact>
            <HexagonContainer deg={deg}>
              <HexagonRow>
                <Hexagon active={active === 'taxi'} deg={hexdeg}>
                  <div>
                    <img src={games.taxi.image} alt="image_game" />
                    <p>{games.taxi.text}</p>
                    {games.taxi.note &&<p dangerouslySetInnerHTML={{__html: games.taxi.note}}></p>}
                  </div>
                </Hexagon>
                <Hexagon active={active === 'sert'} deg={hexdeg}>
                  <div>
                    <img src={games.sert.image} alt="image_game" />
                    <p>{games.sert.text}</p>
                    {games.sert.note &&<p dangerouslySetInnerHTML={{__html: games.sert.note}}></p>}
                  </div>
                </Hexagon>
              </HexagonRow>
              <HexagonRow>
                <Hexagon active={active === 'sale_today'} deg={hexdeg}>
                  <div>
                    <img src={games.sale_today.image} alt="image_game" />
                    <p>{games.sale_today.text}</p>
                    {games.sale_today.note && <p dangerouslySetInnerHTML={{__html: games.sale_today.note}}></p>}
                  </div>
                </Hexagon>
                <Hexagon onClick={play} deg={hexPlay}>
                  <div>
                    <img src={start} alt="image_game" />
                  </div>
                </Hexagon>
                <Hexagon active={active === 'bar'} deg={hexdeg}>
                  <div>
                    <img src={games.bar.image} alt="image_game" />
                    <p>{games.bar.text}</p>
                    {games.bar.note &&<p dangerouslySetInnerHTML={{__html: games.bar.note}}></p>}
                  </div>
                </Hexagon>
              </HexagonRow>
              <HexagonRow>
                <Hexagon active={active === 'bottle'} deg={hexdeg}>
                  <div>
                    <img src={games.bottle.image} alt="image_game" />
                    <p>{games.bottle.text}</p>
                    {games.bottle.note &&<p dangerouslySetInnerHTML={{__html: games.bottle.note}}></p>}
                  </div>
                </Hexagon>
                <Hexagon active={active === 'sale_t'} deg={hexdeg}>
                  <div>
                    <img src={games.sale_t.image} alt="image_game" />
                    <p>{games.sale_t.text}</p>
                    {games.sale_t.note &&<p dangerouslySetInnerHTML={{__html: games.sale_t.note}}></p>}
                  </div>
                </Hexagon>
              </HexagonRow>
            
            
            
            </HexagonContainer>
            <Tree />
          
            <TopText>
              <Link to="admin">условия акции и&nbsp;информацию опризах уточняйте у&nbsp;официанта</Link>
              <p style={{opacity: 0.8, color: '#cfaf63', fontWeight: 'bold'}}>{tablet_number}</p>

            </TopText>
            <BottomText>Чрезмерное  употребление  алкоголя вредит вашему  здоровью</BottomText>
          </Route>
          <Route path="/admin" exact>
            <TopText>
              <Link to="/">К игре</Link>

            </TopText>
            <Title>условия акции и&nbsp;информация о&nbsp;призах</Title>
            <Text>
              <p>
                При заказе 2х&nbsp;коктейлей на&nbsp;ROKU GIN на&nbsp;выбор (Early Winter, Martinez Falls,Roku Hot Pink) или 2х&nbsp;порций ROKU GIN в&nbsp;чистом виде, вы&nbsp;можете гарантированнополучить один из&nbsp;указанных ниже подарков при помощи программы рандомного выбора:
              </p>
              <div>
                <p>Тип подарка</p>
                <ul>
                  <li>Сертификат на ужин на сумму 2000 руб. в нашем ресторане</li>
                  <li>Брендированная термобутылка</li>
                  <li>Один Бесплатный коктейль (на выбор Early Winter, Martinez Falls, Roku Hot Pink) в текущем заказе</li>
                  <li>Промо-Код на поездку в Яндекс.Такси на сумму 300р (купон будет действителен на территории Российской Федерации, до 31.05.2021 года).</li>
                  <li>Скидка на 1 из коктейлей в заказе в размере 15% (Early Winter, Martinez Falls, Roku Hot Pink)</li>
                  <li>Скидка на 1 новый коктейлей из предложенных вариантов в размере 25% (Early Winter, Martinez Falls, Roku Hot Pink)</li>
                </ul>
                <p>Акция действует в период с 21.12.2020 по 08.03.2021 в данном ресторане. Акция действует при наличии товара ROKU и подарков. Подробную информацию об организаторе акции, условиях и порядке проведения акции, о подарках и порядке их получения уточняйте у официантов. Количество подарков ограничено. 18+</p>
              </div>
            </Text>
            <BottomText>Чрезмерное  употребление  алкоголя вредит вашему  здоровью</BottomText>

          </Route>
          <Route path="/prizes">
            
              <TopText>
                <Link to="/">К игре</Link>
              </TopText>
              {!isAuth && <LoginForm>
              <div>
                <input autoComplete="off" name="password" value={password} type="password" required placeholder="пароль" onChange={setValue} />
                <button type="button" onClick={login}>Войти</button>
              </div>
            </LoginForm>}
            {isAuth && <>
              <Control>
                <div>
                  {Object.keys(games).map((el , id) => <div key={id.toString()}>
                    <span>{games[el as keyof Games].text} {games[el as keyof Games].note ? games[el as keyof Games].note : ''}: {games[el as keyof Games].quantity}</span>
                    <div>
                      <button onClick={() => addItem(el as keyof Games)}>+</button>
                      <button onClick={() => removeItem(el as keyof Games)}>-</button>
                    </div>
                  </div>)}
                </div>
                <div>
                  <button onClick={reset} style={{width: 'auto', marginTop: '40px', padding: '16px', height: 'auto'}} type="button">Сбросить</button>
                </div>
              </Control>
            </>}
          </Route>
        </Switch>
  </>);
}

const LinkInst = styled.a`
  position: absolute;
  display: flex;
  align-items: center;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  text-decoration: none;
  color: #000;
  top: 88px;
  left: 22px;
  z-index: 1000;


  img {
    width: 18px;
    height: 17px;
    margin-right: 4px;
  }
`

const LoginForm = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  >div {
    display: flex;
    flex-direction: column;
    >input {
      margin-bottom: 16px;
      font-size: 24px;
    }
  }
`

const AppComp = styled.div`
  padding: 16px;
  display: flex;
  align-items: stretch;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
`

const ContainerComp = styled.div`
  position: relative;
  width: 100%;
  border: 6px solid #CFAF63;
  background-image: url(${gbImage});
  box-sizing: border-box;
  overflow: hidden;
`

const Tree = styled.div`
  position: absolute;
  bottom: 0;
  right: -130px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: url(${tree});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  max-width: 600px;
`

const BottomText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  text-align: center;
  padding-bottom: 0;
`

const Title = styled.h1`
  padding-top: 30px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
`

const Text = styled.div`
  padding: 0 32px;
  font-size: 14px;
  p {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.4;
  }
  div {
    padding-left: 24px;
  }
  div>p {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
  }
  ul {
    padding-left: 10px;
    margin-bottom: 4px;
  }
  ul + p {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    max-width: 360px;
    margin-left: -32px;
  }
`

const TopText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  position: absolute;
  padding-bottom: 40px;
  top: 16px;
  right: 16px;
  font-size: 14px;
  line-height: 1.2;
  text-align: right;
  text-transform: uppercase;
  max-width: 340px;
  z-index: 1000;
  a {
    text-decoration: none;
    color: #000000;
  }
`
const Logo = styled(Link)`
  position: absolute;
  left: 16px;
  top: 16px;
  width: 87.2px;
  z-index: 100;
  img {
    width: 100%;
    display: block;
  }
`

const HexagonContainer = styled('div')<{deg: number}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 60%;
  top: 60px;
  left: 0px;
  transform: ${props => `rotate(${props.deg}deg)`};
  transform-origin: center;
  transition: transform 5s cubic-bezier(0.87, 0, 0.13, 1);
  will-change: transform;
`

const HexagonRow = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0;

  :nth-child(2) {
    margin: -28px 0;
  }
`

const Hexagon = styled('div')<{deg: number, active?: boolean}>`
  width:120px;
  height: 137px;
  position: relative;
  margin-right: 8px;
  transform: ${props => `rotate(${props.deg}deg)`};
  transform-origin: center;
  transition: transform 5s cubic-bezier(0.87, 0, 0.13, 1);
  :last-child {
    margin-right: 0;
  }
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    > img {
      max-width: 91px;
      max-height: 70px;
    }
    > p {
      margin: 0;
      max-width: 90px;
      font-family: Lato;
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      text-transform: uppercase;

    }
    > p:nth-child(3) {
      font-size: 6px;
      line-height: 1.3;
    }
  }
  ::before {
    content: '';
    background-image: ${props => `url(${props.active ? hexagonActive : hexagon})`};
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    background-size: cover;
  }
  

`

const Control = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  >div {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    >div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
  }
  span {
    margin-right: 16px;
  }
  button {
    margin-right: 8px;
    font-size: 24px;
    width: 32px;
    height: 32px;
  }
`

export default App;
